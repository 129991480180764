/**
 * Timetastic
 * Features > Email Reports page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import SignupButton from "src/components/signupButton"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Track who’s absent from work with Timetastic"
const description =
  "Keep track of who’s absent from work using Timetastic. Receive daily and weekly absence summaries straight to your inbox."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-features-intro is-unanimated">
                <div className="c-features-intro__description">
                  <h1 className="h h2 c-features-intro__title">
                    Email Reports
                  </h1>
                  <p className="c-features-intro__summary">
                    Stay informed with daily or weekly absence reports.
                  </p>
                  <p>
                    Every morning, straight into your inbox you’ll get an email
                    telling you who’s off that day.
                  </p>
                  <p>Or a week ahead email, so there’s no nasty suprises.</p>
                  <p>
                    <SignupButton buttonCopy="Start a free trial today" trackCtaLabel="FeaturesSignUp" />

                  </p>
                </div>
                <div className="c-features-intro__media-er">
                  <img
                    loading="eager"
                    alt="Annual Leave"
                    className="c-features-intro__image"
                    src="/images/features/email-reports/email-hero-amrita.png"
                  />{" "}
                  <img
                    loading="eager"
                    alt="Training"
                    className="c-features-intro__image"
                    src="/images/features/email-reports/email-hero-dakarai.png"
                  />{" "}
                  <img
                    loading="eager"
                    alt=""
                    className="c-features-intro__image"
                    src="/images/features/email-reports/email-hero-sophie.png"
                  />{" "}
                  <img
                    loading="eager"
                    alt="Sabbatical"
                    className="c-features-intro__image"
                    src="/images/features/email-reports/email-hero-myra.png"
                  />
                </div>
              </div>
            </div>
          </section>

          <FeaturesQuotes
            quotes={[
              {
                avatar:
                  "/images/features/email-reports/stuart-brown-magneticnorth.jpg",
                company: "magneticNorth",
                name: "Stuart Brown",
                quote:
                  "With the team working remotely, the email reporting has been extremely useful.",
              },
              {
                avatar: "/images/features/email-reports/lee-bolam-extract.jpg",
                company: "Extract Coffee",
                name: "Lee Bolam",
                quote:
                  "The daily email is a saviour, I don’t need to keep checking calendars to know what’s going on.",
              },
            ]}
          />

          <section className="c-section c-features-xyz">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="eager"
                    alt="Week ahead email report"
                    className="c-features-grid__image"
                    src="/images/features/email-reports/your-week-ahead.png"
                    width={539}
                    height={288}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Your Week Ahead
                  </h2>
                  <p className="c-features-grid__title">
                    Want to know who’s off work this week?
                  </p>
                  <p>
                    At the start of each week Timetastic will send you a summary
                    of who’s off this week. Set the day and time that works best
                    for you.
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Today’s Absences
                  </h2>
                  <p className="c-features-grid__title">
                    If someone’s off today - you’ll be told
                  </p>
                  <p>
                    No more asking around wondering why someone’s not at work.
                    If someone in your department is off today, Timetastic will
                    tell you, first thing in the morning.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Taday's absence email report"
                    className="c-features-grid__image"
                    src="/images/features/email-reports/todays-absences.png"
                    width={539}
                    height={288}
                  />
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Birthday reminder email"
                    className="c-features-grid__image"
                    src="/images/features/email-reports/birthday-bonus.png"
                    width={539}
                    height={124}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Birthday Bonus</h2>
                  <p className="c-features-grid__title">Happy Birthdays!</p>
                  <p>
                    If you manage a team, a little help goes a long way.
                    Timetastic will send you a nice reminder a few days before
                    someone’s birthday.
                  </p>
                  <p>In case you need to get them a card or cake.</p>
                </div>
              </div>
            </div>
          </section>

          <FeaturesCrossLinks hidden="Email Reports" />

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default;
